<template>
  <el-dialog
    append-to-body
    width="500px"
    :title="`${isChange ? '编辑车辆' : '添加车辆'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    >
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='100px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="车牌号" prop="number">
          <el-input
            v-model.trim="form.data.number"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="车身颜色" prop="color">
          <el-input
            v-model.trim="form.data.color"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="品牌/型号" prop="brand">
          <el-input
            v-model.trim="form.data.brand"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="运输温度" prop="insulation_level">
          <el-select v-model="form.data.insulation_level">
            <el-option label="常温(环境温度)" :value="1"></el-option>
            <el-option label="冷藏(0~-10℃)" :value="2"></el-option>
            <el-option label="冷冻(-10℃~-22℃)" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车辆照片" prop="image">
          <el-upload
            action="/api/common/qiniu/upload"
            :limit="1"
            list-type="picture-card"
            class="imgWrap"
            :on-preview="handlePictureCardPreview"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :on-remove="handleRemove"
            :file-list="fileList"
            accept="image/jpeg, image/png, image/gif"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisibleUrl" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
      </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import { REG_EXP } from '@/util'
  export default {
    name: 'companyContractEdit',
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            id: '',
            number: '',
            color: '',
            brand: '',
            insulation_level: '',
            image: '',
          },
          rules: {
            number: [
              { required: true, message:'必填项', trigger: 'change'},
              {
                pattern: REG_EXP.plateNumber,
                message: '请输入正确的车牌号',
                trigger: 'blur'
              }
            ],
            color: [{ required: true, message:'必填项', trigger: 'change'}],
            brand: [{ required: true, message:'必填项', trigger: 'change'}],
            insulation_level: [{ required: true, message:'必填项', trigger: 'change'}],
            image: [{ required: true, message:'必填项', trigger: 'change'}],
          }
        },
        fileList: [],
        dialogImageUrl: '',
        dialogVisibleUrl: false,
        
      }
    },
    methods: {
      getDetail(row) {
        if(!!row.id) {
          this.isChange = true
          this.$nextTick(() => {
            common.deepClone(this.form.data, row)
            this.fileList = [{uid:'',url:row.image}]
          })
        }
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        this.fileList = [];
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            this.form.data.image = this.fileList[0].url;
            let apiUrl = !!this.isChange ? '/admin/cars/edit' : '/admin/cars/create'
            this.form.loading = true;
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code == 1) {
                this.toggle(false)
                this.$message.success("操作成功！")
                this.$emit('refresh')
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      // 图片上传成功
      handleAvatarSuccess(res, file) {
        this.form.data.image = res.data.all_path_url;
        let strImg = {
          url: res.data.all_path_url,
          name: ''
        }
        this.fileList.push(strImg)
        this.$refs.elFormDom.clearValidate();
      },
      handleRemove(file, fileList) {
        this.form.data.image = '';
        if(this.fileList.length > 0) {
          this.fileList.forEach((v, i) => {
            if(file.uid === v.uid) {
              this.fileList.splice(i,1)
            }
          })
        }
      },
      // 图片上传之前
      beforeAvatarUpload(file) {
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isLt20M) {
          this.$message.warning('上传图片大小不能超过 20MB!');
        }
        return isLt20M;
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisibleUrl = true;
      },

    }
  }
</script>

<style scoped>
.imgWrap {
  width: 90px;
  overflow: hidden;
  height: 80px;
}
.imgWrap ::v-deep .el-upload--picture-card {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
.imgWrap ::v-deep .el-upload-list__item{
  width: 80px !important;
  height: 80px !important;
}
</style>