<template>
  <!-- 领用记录 -->
  <div>
    <div class="addPerson__wrap" v-show="visible">
      <el-header class="shadow page-header__wrap">
        <div class="flex">
          <div class="backBtn" @click="handleReturn">
            <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
            <span>返回</span>
          </div>
          <div class="title">
            <span class="site">当前位置：</span>
            {{ $route.meta.title }}
            <span> > </span>
            领用记录
          </div>
        </div>
      </el-header>
      
      <div class="main">
        <div class="mainBox">
          <div style="display:flex;align-items: center;margin-bottom:10px;font-size:14px;">
            <div>
              <span style="color:#999;">车牌号：</span>
              <span style="color:#909399;font-weight:bold;">{{carInfo.number}}</span>
            </div>
            <div style="margin-left:50px;">
              <span style="color:#999;">车辆状态：</span>
              <span style="color:#909399;font-weight:bold;">{{carInfo.use_status == 1 ? '空闲中' : '使用中'}}</span>
            </div>
          </div>
          <el-table
            :data="table.data"
            style="width: 100%"
            :row-key="row => row.id"
            border
            size="mini"
            :header-cell-style="{background:'#f5f5f5'}">
            <el-table-column
              type="index"
              label="序号"
              width="60">
            </el-table-column>
            <el-table-column property="rider_name" label="领用人"></el-table-column>
            <el-table-column property="start_day" label="开始时间"></el-table-column>
            <el-table-column property="end_day" label="结束时间"></el-table-column>
            <el-table-column property="use" label="用途"></el-table-column>
            <el-table-column property="status" label="还车情况">
              <template slot-scope="{row}">
                <span v-if="row.status == 0"></span>
                <span v-if="row.status == 1">正常</span>
                <span v-if="row.status == 2" class="delBtn" style="text-decoration: underline;cursor: pointer;" @click="showDamage(row)">损坏</span>
                <span></span>
                <span></span>
              </template>
            </el-table-column>
            <el-table-column property="return_img" label="还车照片" width="180px">
              <template slot-scope="{row}">
                <el-image
                  v-for="(img,i) in row.return_img"
                  :key="i"
                  style="width: 40px; height: 40px;margin-right:10px;"
                  :src="img"
                  fit="cover"
                  :preview-src-list="row.return_img"></el-image>
              </template>
            </el-table-column>
            <el-table-column property="create_time" label="创建时间"></el-table-column>
          </el-table>
          <div class='pagination__wrap' v-if="table.total > 10" style="text-align:right;margin-top:10px;">
            <el-pagination
              background
              layout="total, prev, pager, next"
              :current-page="table.params.page"
              :page-size="table.params.count"
              :total="table.total"
              :page-sizes="[10, 20, 50, 100, 150]"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
        <div class="footerWrap">
          <!-- 如果状态是使用中有还车按钮 "use_status":1-空闲中；2-使用中-->
          <el-button type="primary" size="medium" v-if="carInfo.use_status == 2" @click="handleReturnCar">还车</el-button>
          <el-button type="primary" size="medium" @click="handleAddRecords">添加记录</el-button>
        </div>
      </div>
    </div>

    <!-- 领用登记 -->
    <el-dialog
      title="领用登记"
      width="500px"
      center
      :modal="true"
      :append-to-body="true"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :visible.sync="dialogVisibleLend"
      :before-close="handleClose">
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom1'
        label-width='100px'
        label-position='right'
        :model='formLend.data'
        :rules='formLend.rules'
        :size="subUnitSize"
      >
        <el-form-item label="领用人" prop="rider_id">
          <el-select v-model="formLend.data.rider_id" @focus="getRider" placeholder="请选择" class="width100">
            <el-option
              v-for="item in riderArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使用时间" prop="day">
          <el-date-picker
            v-model="formLend.data.day"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            style="width: 100%;">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="用途" prop="use">
          <el-input
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 4}"
            placeholder="请输入内容"
            v-model="formLend.data.use">
          </el-input>
        </el-form-item>
        <el-alert
          title="温馨提示：请严格按照配送路线使用，遵守交通规则注意行驶安全，用车结束请及时登记还车。"
          type="warning"
          :closable="false">
        </el-alert>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button type="primary" :loading="formLend.loading" :disabled="formLend.loading" @click='confirm1'>确定</el-button>
        </el-row>
      </template>
    </el-dialog>

    <!-- 还车登记 -->
    <el-dialog
      title="还车登记"
      width="500px"
      center
      :modal="true"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :visible.sync="dialogVisibleReturn"
      :before-close="handleClose2">
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom2'
        label-width='120px'
        label-position='right'
        :model='formReturn.data'
        :rules='formReturn.rules'
        :size="subUnitSize"
      >
        <el-form-item label="车辆状况" prop="status">
          <el-radio-group v-model="formReturn.data.status">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="2">损坏</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="formReturn.data.status == 2">
          <el-form-item label="损坏部位" prop="breakdown_unit">
            <el-input
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 4}"
              placeholder="请输入"
              v-model="formReturn.data.breakdown_unit">
            </el-input>
          </el-form-item>
          <el-form-item label="损坏原因" prop="breakdown_reason">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="请输入"
              v-model="formReturn.data.breakdown_reason">
            </el-input>
          </el-form-item>
        </template>
        <el-form-item label="车辆照片(1-3)" prop="return_img">
          <el-upload
            action="/api/common/qiniu/upload"
            :limit="3"
            list-type="picture-card"
            class="imgWrap"
            :on-preview="handlePictureCardPreview"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :on-remove="handleRemove"
            :file-list="fileList"
            accept="image/jpeg, image/png, image/gif"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisibleUrl" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button type="primary" :loading="formReturn.loading" :disabled="formReturn.loading" @click='confirm2'>确认还车</el-button>
        </el-row>
      </template>
    </el-dialog>

    <!-- 损坏信息 -->
    <el-dialog title="损坏信息" width="500px" append-to-body :visible.sync="dialogVisibleDamage" center :close-on-click-modal="false">
      <el-descriptions class="margin-top" title="" :column="1"  border>
        <el-descriptions-item label="损坏部位" :labelStyle='labelStyle'>{{refundInfo.breakdown_unit}}</el-descriptions-item>
        <el-descriptions-item label="损坏原因" :labelStyle='labelStyle'>{{refundInfo.breakdown_reason}}</el-descriptions-item>
        <el-descriptions-item label="车辆照片" :labelStyle='labelStyle'>
          <el-image
            v-for="(img,i) in refundInfo.return_img"
            :key="i"
            style="width: 40px; height: 40px;margin-right:10px;"
            :src="img"
            fit="cover"
            :preview-src-list="refundInfo.return_img"></el-image>
        </el-descriptions-item>
        <el-descriptions-item label="登记人" :labelStyle='labelStyle'>{{refundInfo.return_name}}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>

  </div>
</template>

<script>
  import { mixinTable } from '@/mixins/table.js'
  export default {
    name: 'carsRecords',
    mixins: [mixinTable],
    components: {
    },
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        labelStyle: { width: '100px' },
        carInfo: {
          number: '',
          use_status: '',
        },
        table: {
          params:{
            page:1,
            count:10,
            keyword: '',
          },
          total: 0,
          data:[],
        },
        currRow: {},
        dialogTableVisible: false,
        tableData: [],
        dialogVisibleLend : false, // 领用
        formLend: { 
          loading: false,
          data:{
            car_id: "", //车辆ID
            day: [],
            start_day: "", //开始日期
            end_day: "", //结束日期
            rider_id: "", 
            use: "" 
          },
          rules: {
            day: [{ required: true, message:'必填项', trigger: 'change'}],
            rider_id: [{ required: true, message:'必填项', trigger: 'change'}],
            use: [{ required: true, message:'必填项', trigger: 'change'}],
          }
        },
        dialogVisibleReturn: false, // 还车
        formReturn: { 
          loading: false,
          data:{
            id: 1,
            status: 1, //状态：1-正常；2-损坏
            return_img: "", //还车图片，多个以,连接
            breakdown_unit: "", //损坏部位
            breakdown_reason: "" //损坏原因
          },
          rules: {
            status: [{ required: true, message:'必填项', trigger: 'change'}],
            return_img: [{ required: true, message:'必填项', trigger: 'change'}],
            breakdown_unit: [{ required: true, message:'必填项', trigger: 'change'}],
            breakdown_reason: [{ required: true, message:'必填项', trigger: 'change'}],
          }
        },

        dialogVisibleDamage: false,
        refundInfo: {},
        riderArr: [], // 配送员
        fileList: [],
        dialogImageUrl: '',
        dialogVisibleUrl: false,
      }
    },
    methods: {
      getDetail(row) {
        this.currRow = row;
        this.getTable()
      },
      reset(done) {
        this.isChange = false;
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 领用记录列表
      getTable() {
        let _params = {
          car_id: this.currRow.id,
          page: this.table.params.page,
          count: this.table.params.count,
          keyword: this.table.params.keyword,
        }
        this.$http.get("/admin/cars/getList",{params: _params}).then((res) => {
          if (res.code === 1) {
            this.table.loading = false;
            this.carInfo = res.data.car;
            this.table.data = res.data.list;
            this.table.total = res.data.total;
          }
        })
      },
      // 获取配送员
      getRider() {
        this.$http.post('/admin/admin/riderList',{page:1,count:1000}).then(res => {
          if(res.code == 1) {
            this.riderArr = res.data;
          }
        })
      },
      // 返回
      handleReturn() {
        this.$emit("refresh")
        this.toggle(false)
      },
      // 损坏信息
      showDamage(row) {
        this.dialogVisibleDamage = true;
        this.refundInfo = row;
      },
      // 还车
      handleReturnCar() {
        this.dialogVisibleReturn = true;
      },
      // 添加记录
      handleAddRecords() {
        this.dialogVisibleLend = true;
        this.getRider(); // 获取配送员
      },
      // 领用
      confirm1() {
        this.$refs.elFormDom1.validate(valid => {
          if (valid) {
            let _params = { ...this.formLend.data }
            _params.car_id = this.currRow.id; // 车辆id
            _params.start_day = this.formLend.data.day[0];
            _params.end_day = this.formLend.data.day[1];
            delete _params.day
            this.formLend.loading = true;
            this.$http.post('/admin/cars/addGet', _params).then(res => {
              if(res.code == 1){
                this.$message.success('操作成功')
                this.dialogVisibleLend = false;
                this.getTable()
                this.formLend.data = {
                  car_id: "", //车辆ID
                  day: [],
                  start_day: "", //开始日期
                  end_day: "", //结束日期
                  rider_id: "", 
                  use: "" 
                }
              }
            }).finally(() => {
              this.formLend.loading = false;
            })
          }
        })
      },
      // 还车
      confirm2() {
        this.$refs.elFormDom2.validate(valid => {
          if (valid) {
            let _params = { ...this.formReturn.data}
             _params.id = this.table.data.filter(v => v.status == 0)[0].id // 领用记录id
            if(this.formReturn.data.status == 1) {
              delete _params.breakdown_unit;  //损坏部位
              delete _params.breakdown_reason;  //损坏原因
            }
            _params.return_img = this.fileList.map(v => v.url).join(",")
            this.formReturn.loading = true;
            this.$http.post('/admin/cars/returnCar', _params).then(res => {
              if(res.code == 1){
                this.$message.success('操作成功')
                this.dialogVisibleReturn = false;
                this.getTable()
                this.formReturn.data = {
                  id: 1,
                  status: 1, //状态：1-正常；2-损坏
                  return_img: "", //还车图片，多个以,连接
                  breakdown_unit: "", //损坏部位
                  breakdown_reason: "" //损坏原因
                }
              }
            }).finally(() => {
              this.formReturn.loading = false;
            })
          }
        })
      },
      handleClose() {
        this.$refs.elFormDom1.resetFields()
        this.dialogVisibleLend = false;
      },
      handleClose2() {
        this.$refs.elFormDom2.resetFields()
        this.dialogVisibleReturn = false;
      },
      // 图片上传成功
      handleAvatarSuccess(res, file) {
        this.formReturn.data.return_img = res.data.all_path_url;
        let strImg = {
          url: res.data.all_path_url,
          name: ''
        }
        this.fileList.push(strImg)
        this.$refs.elFormDom2.clearValidate();
      },
      handleRemove(file, fileList) {
        if(this.fileList.length > 0) {
          this.fileList.forEach((v, i) => {
            if(file.uid === v.uid) {
              this.fileList.splice(i,1)
            }
          })
        }
      },
      // 图片上传之前
      beforeAvatarUpload(file) {
        const isLt20M = file.size / 1024 / 1024 < 20;
        if (!isLt20M) {
          this.$message.warning('上传图片大小不能超过 20MB!');
        }
        return isLt20M;
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisibleUrl = true;
      },
      
      
     
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.addPerson__wrap .main {
  overflow-x: hidden;
  background: #fff;
}
.returnBtn {
  z-index: 99;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
}
.footerWrap {
  text-align: center;
  padding: 10px;
   box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.itemTime {
  display: block;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.itemTime::before {
  content: "";
  width: 5px;
  height: 15px;
  background: #2cb167;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.after-table {
  padding: 10px;
  font-size: 13px;
  color: #303030;
  text-align: right;
}
.pageWrap {
  margin-top: 10px;
  text-align: right;
  margin-right: 36px;
}

.imgWrap {
  width: 280px;
  overflow: hidden;
  height: 80px;
}
.imgWrap ::v-deep .el-upload--picture-card {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
.imgWrap ::v-deep .el-upload-list__item{
  width: 80px !important;
  height: 80px !important;
}
</style>
