<template>
  <!-- 保险记录 -->
  <div>
    <div class="addPerson__wrap" v-show="visible">
      <el-header class="shadow page-header__wrap">
        <div class="flex">
          <div class="backBtn" @click="handleReturn">
            <el-image class="back-img" :src="require('@/assets/img/back.png')"></el-image>
            <span>返回</span>
          </div>
          <div class="title">
            <span class="site">当前位置：</span>
            {{ $route.meta.title }}
            <span> > </span>
            保险记录
          </div>
        </div>
      </el-header>
      
      <div class="main">
        <div class="mainBox">
          <el-table
            :data="table.data"
            style="width: 100%"
            :row-key="row => row.id"
            border
            size="mini"
            :header-cell-style="{background:'#f5f5f5'}">
            <el-table-column
              type="index"
              label="序号"
              width="60">
            </el-table-column>
            <el-table-column property="name" label="保险公司名称"></el-table-column>
            <el-table-column property="types" label="保险类型">
              <template slot-scope="{row}">
                <span v-if="row.types == '1'">商业险</span>
                <span v-if="row.types == '2'">交强险</span>
                <span v-if="row.types == '1,2' || row.types == '2,1'">交强险 / 商业险</span>
              </template>
            </el-table-column>
            <el-table-column property="end_day" label="保险期限">
              <template slot-scope="{row}">
                <span>{{row.start_day}}至{{row.end_day}}</span>
              </template>
            </el-table-column>
            <el-table-column property="action" label="操作" width="120px">
              <template slot-scope="{row}">
                <el-button type="text" @click="showEdit(row)">编辑</el-button>
                <el-button type="text" @click="remove(row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class='pagination__wrap' v-if="table.total > 10" style="text-align:right;margin-top:10px;">
            <el-pagination
              background
              layout="total, prev, pager, next"
              :current-page="table.params.page"
              :page-size="table.params.count"
              :total="table.total"
              :page-sizes="[10, 20, 50, 100, 150]"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
        <div class="footerWrap">
          <el-button type="primary" size="medium" @click="showEdit">添加</el-button>
        </div>
      </div>
    </div>

    <!-- 添加保险 -->
    <el-dialog
      title="添加保险"
      width="500px"
      center
      :modal="true"
      :append-to-body="true"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :visible.sync="dialogVisible">
      <el-form
        class="staff-from"
        v-loading="loading"
        element-loading-text="加载中…"
        ref='elFormDom'
        label-width='110px'
        label-position='right'
        :model='form.data'
        :rules='form.rules'
        :size="subUnitSize"
      >
        <el-form-item label="保险公司名称" prop="name">
          <el-input
            placeholder="请输入"
            v-model="form.data.name">
          </el-input>
        </el-form-item> 
        <el-form-item label="保险类型" prop="types">
          <el-checkbox-group v-model="form.data.types">
            <el-checkbox label="1">商业险</el-checkbox>
            <el-checkbox label="2">交强险</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        
        <el-form-item label="保险期限" prop="day">
          <el-date-picker
            v-model="form.data.day"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="width: 100%;">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-row type="flex" justify="center">
          <el-button type="primary" :loading="form.loading" @click='confirm'>确定</el-button>
        </el-row>
      </template>
    </el-dialog>

  </div>
</template>

<script>
  import { mixinTable } from '@/mixins/table.js'
  export default {
    name: 'CarsInsurance',
    mixins: [mixinTable],
    components: {
    },
    data() {
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        visible: false,
        isChange: false,
        loading: false,
        table: {
          params:{
            page:1,
            count:10,
            keyword: '',
            car_id: '',
          },
          total: 0,
          data:[],
        },
        currRow: {},
        dialogVisible: false,
        form: { 
          loading: false,
          data:{
            car_id: "", //车辆ID
            name: '',
            day: [],
            start_day: "", //开始日期
            end_day: "", //结束日期
            types: [],
          },
          rules: {
            day: [{ required: true, message:'必填项', trigger: 'change'}],
            types: [{ required: true, message:'必填项', trigger: 'change'}],
            name: [{ required: true, message:'必填项', trigger: 'blur'}],
          }
        },
        typesArr: ['商业险','交强险'],
      }
    },
    methods: {
      getDetail(row) {
        this.currRow = row;
        this.table.params.car_id = row.id;
        this.getTable()
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 列表
      getTable() {
        this.$http.get("/admin/cars/insuranceList",{params: this.table.params}).then((res) => {
          if (res.code === 1) {
            this.table.loading = false;
            this.table.data = res.data.list
            this.table.total = res.data.total;
          }
        })
      },
      // 返回
      handleReturn() {
        this.$emit("refresh")
        this.toggle(false)
      },
      showEdit(row) {
        this.dialogVisible = true;
        if(!!row.id) {
          this.isChange = true;
          this.form.data.id = row.id; // 保险id
          this.form.data.name = row.name;
          this.form.data.types = row.types.split(",");
          this.form.data.day = [row.start_day,row.end_day];
        }
      },
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _params = { ...this.form.data }
            _params.car_id = this.currRow.id; // 车辆id
            _params.id = this.form.data.id; // 保险id，编辑时必传
            _params.start_day = this.form.data.day[0];
            _params.end_day = this.form.data.day[1];
            _params.types = this.form.data.types.join(",");
            delete _params.day
            let apiUrl = !!this.isChange ? '/admin/cars/editInsurance' : '/admin/cars/addInsurance'
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code == 1){
                this.$message.success('操作成功')
                this.dialogVisible = false;
                this.isChange = false;
                this.getTable()
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      remove(row) {
        this.setConfirm(
          `确认删除操作？`,
          {  id: row.id },
          "/admin/cars/deleteInsurance"
        );
      }
    }
  }
</script>

<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
} 
.addPerson__wrap .main {
  overflow-x: hidden;
  background: #fff;
}
.returnBtn {
  z-index: 99;
  box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
}
.footerWrap {
  text-align: center;
  padding: 10px;
   box-shadow: 0 0 8px 0 rgb(232 237 250 / 60%), 0 2px 4px 0 rgb(232 237 250 / 50%);
  z-index: 99;
}
.flex {
  display: flex;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.width200px {
  width: 200px;
}
.after-table {
  padding: 10px;
  font-size: 13px;
  color: #303030;
  text-align: right;
}
.pageWrap {
  margin-top: 10px;
  text-align: right;
  margin-right: 36px;
}


</style>
